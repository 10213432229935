import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';
import { CompanyAddress, WorkHoursGroup } from '../types';
import { Footer } from './Footer';
import { Header } from './Header';
import { LayoutGlobalStyles } from './Layout.styles';

interface LayoutProps {}

export const Layout: React.SFC<LayoutProps> = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutData {
        allDataYaml(filter: { key: { in: ["content", "contact"] } }) {
          edges {
            node {
              key
              seoTitle
              seoDescription
              owner
              brand
              phone
              nip
              regon
              area
              address {
                street
                city
                zipcode
                state
              }
              workHours {
                day
                startTimeInMinutes
                endTimeInMinutes
              }
            }
          }
        }
      }
    `}
  >
    {(data: {
      allDataYaml: {
        edges: Array<{
          node: {
            key: string;
            seoTitle?: string;
            seoDescription?: string;
            owner?: string;
            brand?: string;
            phone?: string;
            nip?: string;
            regon?: string;
            area?: string;
            address?: CompanyAddress;
            workHours: WorkHoursGroup[];
          };
        }>;
      };
    }) => {
      const {
        node: { seoTitle, seoDescription },
      } = data.allDataYaml.edges.find(e => e.node.key === 'content');
      const {
        node: { owner, brand, phone, nip, regon, area, address, workHours },
      } = data.allDataYaml.edges.find(e => e.node.key === 'contact');

      return (
        <React.Fragment>
          <Helmet>
            <html lang="pl" />
            <title>{seoTitle}</title>
            <meta name="description" content={seoDescription} />
            <meta charSet="utf-8" />
          </Helmet>

          <Header />

          {children}

          <Footer
            owner={owner}
            brand={brand}
            phone={phone}
            nip={nip}
            regon={regon}
            area={area}
            address={address}
            workHours={workHours}
          />

          <LayoutGlobalStyles />
        </React.Fragment>
      );
    }}
  </StaticQuery>
);
