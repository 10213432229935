import { lighten } from 'polished';
import styled from 'styled-components';
import {
  Color_Black,
  Color_Yellow,
  Dimension_GutterSmall,
  Dimension_Padding,
  Breakpoint_Phone,
  Dimension_Gutter,
  Dimension_Gap,
} from '../styles';

export const BoxText = styled.p`
  display: inline-block;
  text-align: center;
  background: transparent;
  border: 2px solid ${Color_Black};
  color: ${Color_Black};
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: ${Dimension_GutterSmall} ${Dimension_Padding};
  box-shadow: 0px 0px 8px ${lighten(0.2, Color_Yellow)};

  @media screen and (max-width: ${Breakpoint_Phone}) {
    padding: ${Dimension_Gap} ${Dimension_GutterSmall};
  }
`;
