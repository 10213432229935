import { stripUnit } from 'polished';
import styled from 'styled-components';
import {
  Color_Black,
  Dimension_Wide,
  Dimension_Margin,
  Dimension_Gutter,
  Dimension_Page,
  Color_Yellow,
  Breakpoint_Ultrabook,
  Dimension_MarginSmall,
  Breakpoint_Tablet,
  Dimension_HugeLarge,
  Breakpoint_Phone,
} from '../styles';

export const Banner = styled.div`
  position: relative;
  background: ${Color_Yellow};
  padding-top: ${(stripUnit(Dimension_Margin) as number) * 3 + 'px'};
  z-index: 1;

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    padding-top: ${(stripUnit(Dimension_HugeLarge) as number) * 0.75 + 'px'};
  }
`;

export const BannerText = styled.div`
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 0 ${Dimension_Wide};
  max-width: ${Dimension_Page};
  width: 100%;
  text-align: left;

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Headline = styled.h1`
  font-size: 8rem;
  font-weight: 900;
  color: ${Color_Black};
  padding-left: ${Dimension_Wide};
  border-left: 4px solid ${Color_Black};

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    padding: 0 ${Dimension_MarginSmall};
  }

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    text-align: center;
    border-left: none;
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    font-size: 3rem;
  }
`;

export const Description = styled.h2`
  max-width: 640px;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  box-sizing: content-box;
  color: ${Color_Black};
  padding: 0 ${Dimension_Wide};
  margin-top: ${Dimension_Gutter};

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    padding-left: ${Dimension_MarginSmall};
    padding-right: ${Dimension_MarginSmall};
  }

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    max-width: none;
    text-align: center;
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    font-size: 1.3rem;
  }
`;

export const BannerBackground = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 1280px;
  width: 100%;
  height: auto;
  z-index: 1;
`;
