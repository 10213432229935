import styled from 'styled-components';
import {
  Dimension_Gap,
  Dimension_Padding,
  Dimension_Wide,
  Dimension_Page,
  Breakpoint_Ultrabook,
  Dimension_MarginSmall,
  Breakpoint_Phone,
  Dimension_Margin,
  Dimension_Gutter,
} from '../styles';

export const Container = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: ${Dimension_Page};
  padding: ${Dimension_Padding} ${Dimension_Wide};

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    padding-left: ${Dimension_MarginSmall};
    padding-right: ${Dimension_MarginSmall};
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    padding: ${Dimension_Gutter} ${Dimension_Gap};
  }
`;

export const Logo = styled.img`
  width: auto;
  height: 24px;

  @media screen and (max-width: ${Breakpoint_Phone}) {
    width: auto;
    height: auto;
    max-width: 148px;
    flex: 0 1 auto;
  }
`;

export const Contact = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;

  & > p:not(:first-child) {
    margin-left: ${Dimension_Gap};
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    flex: 1 0 auto;
  }
`;

export const CallNow = styled.p`
  font-size: 1.1rem;
  font-weight: 400;

  @media screen and (max-width: ${Breakpoint_Phone}) {
    display: none;
  }
`;
