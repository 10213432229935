import styled from 'styled-components';
import {
  Color_Black,
  Color_White,
  FontFamily_Serif,
  Dimension_Padding,
  Dimension_PaddingSmall,
} from '../styles';

export const ServiceBox = styled.div<{
  isDark?: boolean;
}>`
  width: 100%;
  height: auto;
  text-align: left;
  background: ${p => (p.isDark ? Color_Black : Color_White)};

  & h3 {
    font-size: 1.3rem;
    font-weight: 700;
    padding: ${Dimension_Padding};
    padding-bottom: 0;
    color: ${p => (p.isDark ? Color_White : Color_Black)};
  }

  & p {
    color: ${p => (p.isDark ? Color_White : Color_Black)};
    padding: 0 ${Dimension_Padding};
    padding-top: ${Dimension_PaddingSmall};
    padding-bottom: ${Dimension_Padding};
    font-family: ${FontFamily_Serif};
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.3;
  }

  & img {
    width: 100%;
    height: auto;
  }
`;
