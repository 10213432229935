import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import { CallNow, Contact, Container, Logo, Wrapper } from './Header.styles';
import { TextInBox } from './TextInBox';

const logo = require('../images/elektrolek-logo.svg');

interface HeaderProps {}

export const Header: React.SFC<HeaderProps> = props => (
  <StaticQuery
    query={graphql`
      query HeaderInfo {
        allDataYaml(filter: { key: { eq: "contact" } }) {
          edges {
            node {
              brand
              phone
            }
          }
        }
      }
    `}
    render={(data: {
      allDataYaml: {
        edges: Array<{
          node: {
            brand: string;
            phone: string;
          };
        }>;
      };
    }) => {
      const {
        node: { brand, phone },
      } = data.allDataYaml.edges[0];

      return (
        <Container>
          <Wrapper>
            <Logo src={logo} alt={brand.toUpperCase()} />

            <Contact>
              <CallNow>Zadzwoń:</CallNow>
              <TextInBox>{phone}</TextInBox>
            </Contact>
          </Wrapper>
        </Container>
      );
    }}
  />
);
