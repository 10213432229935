import { darken, lighten } from 'polished';
import styled from 'styled-components';
import {
  Color_Light,
  Dimension_Gutter,
  Dimension_Padding,
  Color_Grey,
  Dimension_Gap,
  Dimension_GapSmall,
} from '../styles';

export const Container = styled.div`
  border: 1px solid ${darken(0.08, Color_Light)};
  padding: ${Dimension_Gutter} ${Dimension_Padding};
  border-radius: 2px;
`;

export const Title = styled.p`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${Color_Grey};
`;

export const ShortText = styled.p`
  font-size: 0.95rem;
  line-height: 1.3;
  margin-top: ${Dimension_GapSmall};
  color: ${lighten(0.2, Color_Grey)};
`;

export const LongText = styled(ShortText)`
  margin-top: ${Dimension_Gap};
  white-space: pre-line;
`;
