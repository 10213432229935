import * as React from 'react';
import { formatHoursAndMinutesFromTotalMinutes } from '../helpers/time';
import { CompanyAddress, WorkHoursGroup } from '../types';
import {
  BoxTitle,
  Container,
  Map,
  Wrapper,
  BoxForMap,
  BoxContent,
  BoxSeparator,
  BoxTextLine,
  Legal,
  BoxForAddress,
  BoxForContact,
  FooterBorderBoxForWorkHours,
  FooterBorderBox,
} from './Footer.styles';

const backgroundMap = require('../images/map.jpg');

interface FooterProps {
  owner: string;
  brand: string;
  phone: string;
  nip: string;
  regon: string;
  area: string;
  address: CompanyAddress;
  workHours: WorkHoursGroup[];
}

export const Footer: React.SFC<FooterProps> = props => {
  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <Wrapper>
        <BoxForMap>
          <BoxTitle>Obszar</BoxTitle>
          <Map
            src={backgroundMap}
            alt="Mapa pokazująca obszar działania firmy Elektrolek"
          />
        </BoxForMap>

        <BoxForAddress>
          <BoxTitle>Lokalizacja</BoxTitle>

          <BoxContent>
            <FooterBorderBox title="Obszar działania" longText={props.area} />

            <FooterBorderBox
              title="Siedziba firmy"
              shortText={props.address.state}
              longText={`${props.address.street}\n${props.address.city} ${
                props.address.zipcode
              }`}
            />

            <FooterBorderBox
              title="Informacje"
              longText={`NIP: ${props.nip}\nREGON: ${props.regon}`}
            />
          </BoxContent>
        </BoxForAddress>

        <BoxForContact>
          <BoxTitle>Kontakt</BoxTitle>

          <BoxContent>
            <FooterBorderBox
              title="Dane kontaktowe"
              shortText={`${props.brand} ${props.owner}`}
              longText={`Tel: ${props.phone}`}
            />

            <FooterBorderBoxForWorkHours
              title="Dostępność telefoniczna"
              longText={props.workHours.reduce(
                (text: string, workHoursGroup) => {
                  text += workHoursGroup.day;
                  text += ': \n';

                  if (
                    workHoursGroup.startTimeInMinutes ||
                    workHoursGroup.endTimeInMinutes
                  ) {
                    text +=
                      formatHoursAndMinutesFromTotalMinutes(
                        workHoursGroup.startTimeInMinutes
                      ) +
                      ' - ' +
                      formatHoursAndMinutesFromTotalMinutes(
                        workHoursGroup.endTimeInMinutes
                      );
                  } else {
                    text += 'Nieczynne';
                  }

                  text += '\n\n';

                  return text;
                },
                ''
              )}
            />

            <BoxSeparator />

            <BoxTextLine>
              <p>
                {`Wszelkie prawa zastrzeżone © ${currentYear}\n` +
                  `${props.brand} ${props.owner}`}
              </p>
            </BoxTextLine>
          </BoxContent>
        </BoxForContact>
      </Wrapper>

      <Legal>
        <p>
          Wszystkie znaki towarowe poza znakami ELEKTROLEK i VIDUA stanowią
          własność odpowiednich firm.
        </p>
        <p>
          <span>
            {`Wszelkie prawa zastrzeżone © ${currentYear} ${props.brand} ${
              props.owner
            }`}
          </span>
          Strona wykonana i zaprojektowana przez{' '}
          <a href="https://vidua.pl">VIDUA</a>
        </p>
      </Legal>
    </Container>
  );
};
