import { stripUnit } from 'polished';
import styled from 'styled-components';
import { LandingPageService } from './LandingPage.Service';
import {
  Dimension_Huge,
  Color_White,
  Dimension_Page,
  Dimension_Wide,
  Dimension_MarginSmall,
  Dimension_Margin,
  Color_Grey,
  Breakpoint_Laptop,
  Dimension_Gutter,
  Breakpoint_Ultrabook,
  Breakpoint_Tablet,
  Dimension_HugeLarge,
  Breakpoint_Phone,
} from '../styles';

export const ServicesContainer = styled.div`
  z-index: 2;
  position: relative;
  display: block;
  width: 100%;
  padding-top: ${Dimension_Huge};

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    padding-top: ${Dimension_HugeLarge};
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    padding-top: ${Dimension_Wide};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    width: 50%;
    height: calc(100% - ${Dimension_Huge});
    background: ${Color_White};

    @media screen and (max-width: ${Breakpoint_Phone}) {
      display: none;
    }
  }
`;

export const ServicesWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  max-width: ${Dimension_Page};
`;

export const ServicesBox = styled.div`
  position: relative;
  margin: 0;
  margin-left: ${Dimension_Wide};
  padding-bottom: ${Dimension_Margin};
  background: ${Color_White};

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    margin-left: 0;
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    padding-bottom: ${Dimension_Gutter};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: ${(stripUnit(Dimension_Margin) as number) * -1 + 'px'};
    left: 0;
    width: 100%;
    height: 1px;
    background: ${Color_Grey};
  }
`;

export const ServicesGrid = styled.div`
  display: grid;
  grid-template: auto / 1fr 1fr 1fr;
  width: 100%;
  max-width: ${Dimension_Page};
  gap: ${Dimension_MarginSmall};
  margin: 0;
  padding: ${Dimension_Margin} ${Dimension_Wide};
  padding-bottom: 0;
  padding-top: 0;

  @media screen and (max-width: ${Breakpoint_Laptop}) {
    gap: ${Dimension_Gutter};
  }

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    padding-left: ${Dimension_MarginSmall};
    padding-right: ${Dimension_MarginSmall};
  }

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    grid-template: auto / 100%;
    gap: 0;
  }
`;

export const Service = styled(LandingPageService)`
  margin-top: ${(stripUnit(Dimension_Margin) as number) * -2 + 'px'};

  @media screen and (max-width: ${Breakpoint_Phone}) {
    margin-top: 0;
  }
`;
