import { stripUnit } from 'polished';
import styled from 'styled-components';
import { BorderBox } from './BorderBox';
import { LongText } from './BorderBox.styles';
import {
  Color_Light,
  Dimension_Padding,
  Dimension_Page,
  Dimension_Wide,
  Color_White,
  Color_Black,
  Dimension_MarginSmall,
  Dimension_Gap,
  Color_Grey,
  Color_Blue,
  Color_BrandOrange,
  Breakpoint_Laptop,
  Dimension_Gutter,
  Breakpoint_Ultrabook,
  Breakpoint_Tablet,
  Breakpoint_Phone,
} from '../styles';

const mapSize = '1038px';
const mapSizeHalf = (stripUnit(mapSize) as number) / 2 + 'px';
const mapSizeHalfNegative = (stripUnit(mapSizeHalf) as number) * -1 + 'px';

export const Container = styled.div`
  width: 100%;
  position: relative;
  background: ${Color_Light};
  padding: ${Dimension_MarginSmall} 0;
  overflow: hidden;

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    padding-top: ${Dimension_Gutter};
    padding-bottom: ${Dimension_Gutter};
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template: auto / 2fr 1fr 1fr;
  gap: ${Dimension_MarginSmall};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${Dimension_Wide};
  max-width: ${Dimension_Page};

  @media screen and (max-width: ${Breakpoint_Laptop}) {
    grid-template-columns: 3fr 2fr 2fr;
    gap: ${Dimension_Gutter};
  }

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    grid-template-columns: 1fr 1fr;
    padding-left: ${Dimension_MarginSmall};
    padding-right: ${Dimension_MarginSmall};
  }

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    grid-template: auto / 100%;
  }
`;

export const BoxTitle = styled.h5`
  z-index: 2;
  position: relative;
  padding: ${Dimension_Padding};
  font-size: 1.3rem;
  font-weight: 700;
  color: ${Color_Black};
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  padding: ${Dimension_Padding};
  padding-top: 0;

  & > div:not(:first-child) {
    margin-top: ${Dimension_Gap};
  }
`;

export const BoxSeparator = styled.div`
  width: 100%;
  height: ${Dimension_Padding};
  border-radius: 2px;
  background: ${Color_Light};
`;

export const BoxTextLine = styled.div`
  text-align: right;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.4;
  color: ${Color_Grey};
  white-space: pre-line;
`;

export const FooterBorderBox = styled(BorderBox)`
  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    padding: ${Dimension_Gutter};
  }
`;

export const FooterBorderBoxForWorkHours = styled(FooterBorderBox)``;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  flex: 1 0 auto;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: ${Color_White};
`;

export const BoxForMap = styled(Box)`
  width: 100%;
  height: 100%;
  min-width: 320px;
  max-width: ${mapSize};

  @media screen and (max-width: ${Breakpoint_Phone}) {
    min-height: 520px;
  }
`;

export const BoxForAddress = styled(Box)``;

export const BoxForContact = styled(Box)`
  @media screen and (min-width: ${Breakpoint_Phone}) and (max-width: ${Breakpoint_Ultrabook}) {
    grid-column: 1 / span 2;

    & ${BoxContent} {
      flex-direction: row;

      & > div {
        margin-top: 0;

        &:not(:first-child) {
          margin-left: ${Dimension_Gap};
        }
      }
    }

    & ${FooterBorderBoxForWorkHours} {
      flex: 1 0 auto;

      & ${LongText} {
        column-count: 3;
        column-gap: ${Dimension_Gutter};
      }
    }

    & ${BoxSeparator} {
      display: none;
      width: ${Dimension_Padding};
      height: 100%;
    }

    & ${BoxTextLine} {
      display: none;
    }
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    & ${BoxSeparator} {
      display: none;
    }

    & ${BoxTextLine} {
      display: none;
    }
  }
`;

export const Map = styled.img`
  z-index: 1;
  position: absolute;
  width: ${mapSize};
  height: ${mapSize};
  top: 50%;
  left: 50%;
  margin-top: ${mapSizeHalfNegative};
  margin-left: ${mapSizeHalfNegative};
`;

export const Legal = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-align: left;
  margin-top: ${Dimension_MarginSmall};

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    padding-left: ${Dimension_Padding};
    padding-right: ${Dimension_Padding};
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    margin-top: ${Dimension_Gutter};
    flex-direction: column;

    & p {
      width: 100%;
      text-align: center !important;

      &:not(:first-child) {
        margin-top: ${Dimension_Gap};
      }
    }
  }

  & p {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.4;
    color: ${Color_Grey};

    & span {
      display: none;

      @media screen and (max-width: ${Breakpoint_Ultrabook}) {
        display: block;
      }
    }

    &:last-child {
      text-align: right;
    }

    & a {
      border-bottom: 1px dotted ${Color_Blue};
      text-decoration: none;
      color: ${Color_Grey};
      transition: 300ms all 0ms ease-in-out;

      &:hover {
        color: ${Color_BrandOrange};
        border-bottom-color: ${Color_BrandOrange};
      }
    }
  }
`;
