import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  Dimension_Padding,
  Dimension_Page,
  Dimension_Wide,
  Color_Black,
  FontFamily_Serif,
  Dimension_Margin,
  Dimension_Gap,
  Breakpoint_Ultrabook,
  Dimension_MarginSmall,
  Breakpoint_Tablet,
  Breakpoint_Phone,
} from '../styles';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  padding-top: ${Dimension_Wide};
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${Dimension_Page};
  display: grid;
  grid-template: auto / 4fr 6fr;

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    grid-template-columns: 1fr 2fr;
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    grid-template: auto / 100%;
  }
`;

export const Image = styled.div`
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;

  & img {
    width: 100%;
    height: auto;
    box-shadow: 24px 18px 36px ${transparentize(0.88, Color_Black)};
  }
`;

export const Text = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  grid-column: 2;
  grid-row: 1;
  padding: 8px ${Dimension_Wide};

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    padding-left: ${Dimension_MarginSmall};
    padding-right: ${Dimension_MarginSmall};
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    grid-column: 1;
    grid-row: 2;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: ${Dimension_Wide};
    width: ${Dimension_Margin};
    height: 4px;
    background: ${Color_Black};

    @media screen and (max-width: ${Breakpoint_Ultrabook}) {
      left: ${Dimension_MarginSmall};
    }
  }
`;

export const Headline = styled.h4`
  padding-top: ${Dimension_Gap};
  font-size: 2rem;
  font-weight: 700;

  @media screen and (max-width: ${Breakpoint_Phone}) {
    padding-top: ${Dimension_Padding};
  }
`;

export const Description = styled.p`
  white-space: pre-line;
  font-family: ${FontFamily_Serif};
  font-size: 1.3rem;
  line-height: 1.3;
  font-weight: 400;
  margin-top: ${Dimension_Padding};

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    font-size: 1.2rem;
  }
`;
