import { darken } from 'polished';
import { createGlobalStyle } from 'styled-components';
import {
  Color_Black,
  Color_White,
  FontFamily_Regular,
  Color_Yellow,
  Color_Light,
} from '../styles';

export const LayoutGlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
        line-height: 1;
    }

    body {
        background: ${Color_Yellow};
        font-family: ${FontFamily_Regular};
        color: ${Color_Black};
    }

    div, ol, ul, li, button, form, fieldset, p, h1, h2, h3, h4, h5, h6 {
        box-sizing: border-box;
    }

    p, h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    ol, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    button {
        cursor: pointer;
        margin: 0;
        padding: 0;
        border: 0;
    }

    ::selection {
        background: ${darken(0.1, Color_Yellow)};
        color: ${Color_Black};
    }
`;
