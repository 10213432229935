import * as React from 'react';
import {
  Container,
  Headline,
  Logos,
  Logo,
  Top,
  Bottom,
} from './LandingPage.Partners.styles';

interface LandingPagePartnersProps {
  headline: string;
  logos: Array<{
    brand: string;
    image: string;
  }>;
}

export const LandingPagePartners: React.SFC<LandingPagePartnersProps> = ({
  headline,
  logos,
}) => (
  <Container>
    <Top>
      <Headline>{headline}</Headline>
    </Top>

    <Bottom>
      <Logos>
        {logos.map(logo => (
          <Logo key={logo.brand}>
            <div>
              <img src={logo.image} alt={logo.brand + ' brand logo'} />
            </div>
            <h5>{logo.brand}</h5>
          </Logo>
        ))}
      </Logos>
    </Bottom>
  </Container>
);
