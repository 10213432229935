import * as React from 'react';
import { Container, LongText, ShortText, Title } from './BorderBox.styles';

interface BorderBoxProps {
  className?: string;
  title: string;
  shortText?: string;
  longText?: string;
}

export const BorderBox: React.SFC<BorderBoxProps> = ({
  className,
  title,
  shortText,
  longText,
}) => (
  <Container className={className}>
    <Title>{title}</Title>

    {shortText && <ShortText>{shortText}</ShortText>}

    {longText && <LongText>{longText}</LongText>}
  </Container>
);
