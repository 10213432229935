import { padStart as _padStart } from 'lodash';
import { HoursAndMinutes } from '../types';

export const calculateHoursAndMinutesFromTotalMinutes = (
  totalMinutes: number
): HoursAndMinutes => {
  if (totalMinutes <= 0) {
    return { hours: 0, minutes: 0 };
  }

  const hours = Math.floor(totalMinutes / 60);
  const hoursInMinutes = hours * 60;
  const minutes = totalMinutes - hoursInMinutes;

  return {
    hours,
    minutes: minutes > 0 ? minutes : 0,
  };
};

export const formatHoursAndMinutes = (hm: HoursAndMinutes): string => {
  return (
    _padStart(hm.hours.toString(), 2, '0') +
    ':' +
    _padStart(hm.minutes.toString(), 2, '0')
  );
};

export const formatHoursAndMinutesFromTotalMinutes = (
  totalMinutes: number
): string => {
  return formatHoursAndMinutes(
    calculateHoursAndMinutesFromTotalMinutes(totalMinutes)
  );
};
