import * as React from 'react';
import {
  Banner,
  Headline,
  Description,
  BannerBackground,
  BannerText,
} from './LandingPage.Banner.styles';

const bannerBackgroundImage = require('./../images/banner.jpg');

interface LandingPageBannerProps {
  headline: string;
  description: string;
}

export const LandingPageBanner: React.SFC<LandingPageBannerProps> = ({
  headline,
  description,
}) => (
  <Banner>
    <BannerText>
      <Headline>{headline}</Headline>
      <Description>{description}</Description>
    </BannerText>

    <BannerBackground src={bannerBackgroundImage} alt="" />
  </Banner>
);
