export const Dimension_Page = '1820px';
export const Dimension_HugeLarge = '256px';
export const Dimension_Huge = '196px';
export const Dimension_Wide = '128px';
export const Dimension_MarginLarge = '96px';
export const Dimension_Margin = '64px';
export const Dimension_MarginSmall = '48px';
export const Dimension_Padding = '36px';
export const Dimension_PaddingSmall = '30px';
export const Dimension_Gutter = '24px';
export const Dimension_GutterSmall = '18px';
export const Dimension_Gap = '12px';
export const Dimension_GapSmall = '6px';
