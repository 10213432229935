import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  Color_White,
  Dimension_Wide,
  Dimension_Page,
  Dimension_Margin,
  Color_Grey,
  Color_Yellow,
  Dimension_Gap,
  Dimension_MarginSmall,
  Color_Light,
  Breakpoint_Ultrabook,
  Dimension_Gutter,
  Breakpoint_Tablet,
  Breakpoint_Phone,
  Dimension_GapSmall,
  Dimension_Padding,
  Dimension_Huge,
} from '../styles';

const logoBoxHeight = 256;
const logoBoxHeightHalf = logoBoxHeight / 2;
const logoBoxHeightHalfNegative = logoBoxHeightHalf * -1;

export const Container = styled.div`
  margin: 0;
  padding: 0;
`;

export const Top = styled.div`
  width: 100%;
  padding-top: ${Dimension_Margin};
  background: ${Color_Yellow};
`;

export const Headline = styled.h4`
  display: block;
  border-top: 1px solid ${Color_Grey};
  width: 100%;
  max-width: ${Dimension_Page};
  margin: 0 auto;
  padding: 0;
  padding-top: ${Dimension_Margin};
  padding-bottom: ${logoBoxHeight * 0.64 + 'px'};
  text-align: center;
  font-size: 2rem;
  font-weight: 700;

  @media screen and (max-width: ${Breakpoint_Phone}) {
    padding-bottom: ${Dimension_Gutter};
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    font-size: 1.4rem;
  }
`;

export const Bottom = styled.div`
  background: ${Color_Light};
  height: ${logoBoxHeightHalf + 'px'};

  @media screen and (max-width: ${Breakpoint_Phone}) {
    height: auto;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-content: center;
  align-items: center;
  text-align: center;
  margin: 0 2px;
  width: ${logoBoxHeight * 0.8 + 'px'};
  height: ${logoBoxHeight + 'px'};
  box-sizing: border-box;
  background: ${Color_White};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: ${logoBoxHeight * 0.75 + 'px'};
    width: 100%;
    padding: ${Dimension_MarginSmall};
    padding-bottom: 0;
    margin: 0;

    @media screen and (max-width: ${Breakpoint_Ultrabook}) {
      padding: ${Dimension_Gutter};
      padding-bottom: 0;
    }
  }

  & h5 {
    font-size: 1.3rem;
    font-weight: 700;
    color: ${Color_Grey};
    margin-top: ${Dimension_Gap};
  }

  & img {
    width: 100%;
    height: auto;
  }
`;

export const Logos = styled.div`
  width: 100%;
  position: relative;
  max-width: ${Dimension_Page};
  margin: 0 auto;
  top: ${logoBoxHeightHalfNegative + 'px'};
  padding: 0 ${Dimension_Wide};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${Breakpoint_Ultrabook}) {
    padding-left: ${Dimension_MarginSmall};
    padding-right: ${Dimension_MarginSmall};
  }

  @media screen and (max-width: ${Breakpoint_Tablet}) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: ${Breakpoint_Phone}) {
    display: grid;
    grid-template: auto / 1fr 1fr;
    gap: 2px;
    top: 0;

    & ${Logo} {
      margin: 0;
      padding: 0;
      width: 100%;
      height: auto;

      & > div {
        height: auto;
        margin: 0 auto;
        height: 100%;
        padding: ${Dimension_Gutter} ${Dimension_Padding};
        max-width: ${Dimension_Huge};
      }

      & h5 {
        display: none;
      }
    }
  }
`;
