import * as React from 'react';
import { LandingPageServiceData } from './LandingPage.Service';
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesBox,
  ServicesGrid,
  Service,
} from './LandingPage.Services.styles';

interface LandingPageServicesProps {
  services: LandingPageServiceData[];
}

export const LandingPageServices: React.SFC<LandingPageServicesProps> = ({
  services,
}) => (
  <ServicesContainer>
    <ServicesWrapper>
      <ServicesBox>
        <ServicesGrid>
          {services.map((service, index) => (
            <Service
              key={service.title}
              title={service.title}
              description={service.description}
              image={service.image}
              isDark={index % 2 > 0}
            />
          ))}
        </ServicesGrid>
      </ServicesBox>
    </ServicesWrapper>
  </ServicesContainer>
);
