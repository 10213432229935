import * as React from 'react';
import {
  Container,
  Headline,
  Wrapper,
  Description,
  Text,
  Image,
} from './LandingPage.About.styles';

const sectionImage = require('./../images/board.jpg');

interface LandingPageAboutProps {
  headline: string;
  description: string;
}

export const LandingPageAbout: React.SFC<LandingPageAboutProps> = ({
  headline,
  description,
}) => (
  <Container>
    <Wrapper>
      <Text>
        <Headline>{headline}</Headline>
        <Description>{description}</Description>
      </Text>

      <Image>
        <img src={sectionImage} alt="" />
      </Image>
    </Wrapper>
  </Container>
);
