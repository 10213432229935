import * as React from 'react';
import { ServiceBox } from './LandingPage.Service.styles';

export interface LandingPageServiceData {
  title: string;
  description: string;
  image: string;
  isDark?: boolean;
}

export interface LandingPageServiceProps extends LandingPageServiceData {
  className?: string;
}

export const LandingPageService: React.SFC<LandingPageServiceProps> = ({
  className,
  title,
  description,
  image,
  isDark,
}) => (
  <ServiceBox className={className} isDark={isDark}>
    <img src={image} alt="" />
    <h3>{title}</h3>
    <p>{description}</p>
  </ServiceBox>
);

LandingPageService.defaultProps = {
  className: undefined,
  isDark: false,
};
