import { graphql } from 'gatsby';
import * as React from 'react';
import { LandingPageAbout } from '../components/LandingPage.About';
import { LandingPageBanner } from '../components/LandingPage.Banner';
import { LandingPagePartners } from '../components/LandingPage.Partners';
import { LandingPageServices } from '../components/LandingPage.Services';
import { Layout } from '../components/Layout';
import { GatsbyPageProps } from '../types';

interface IndexPageProps extends GatsbyPageProps {
  data: {
    allDataYaml: {
      edges: Array<{
        node: {
          key: string;
          owner?: string;
          banner?: {
            headline: string;
            description: string;
          };
          services: Array<{
            title: string;
            description: string;
            image: {
              childImageSharp: {
                original: {
                  src: string;
                };
              };
            };
          }>;
          why?: {
            title: string;
            description: string;
          };
          partners?: {
            title: string;
            logos: Array<{
              brand: string;
              image: {
                childImageSharp: {
                  original: {
                    src: string;
                  };
                };
              };
            }>;
          };
        };
      }>;
    };
  };
}

const IndexPage: React.SFC<IndexPageProps> = ({ data }) => {
  const contentEdge = data.allDataYaml.edges.find(
    e => e.node.key === 'content'
  );
  const {
    banner: { headline: bannerHeadline, description: bannerDescription },
    services: servicesGrid,
    why: { title: whyHeadline, description: whyDescription },
    partners: { title: partnersHeadline, logos: partnersLogos },
  } = contentEdge.node;

  return (
    <Layout>
      <LandingPageBanner
        headline={bannerHeadline}
        description={bannerDescription}
      />

      <LandingPageServices
        services={servicesGrid.map(service => ({
          ...service,
          image: service.image.childImageSharp.original.src,
        }))}
      />

      <LandingPageAbout headline={whyHeadline} description={whyDescription} />

      <LandingPagePartners
        headline={partnersHeadline}
        logos={partnersLogos.map(logo => ({
          ...logo,
          image: logo.image.childImageSharp.original.src,
        }))}
      />
    </Layout>
  );
};

export const query = graphql`
  query LandingPageData {
    allDataYaml(filter: { key: { in: ["content", "contact"] } }) {
      edges {
        node {
          key
          owner
          banner {
            headline
            description
          }
          services {
            title
            description
            image {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          why {
            title
            description
          }
          partners {
            title
            logos {
              brand
              image {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
